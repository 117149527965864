import Popover from '@/components/Popover.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'QuestionPopover',
    components: {
        Popover
    },
    props: {
        description: {
            type: [String, Object],
            default: ''
        },
        styles: {
            type: String,
            default: 'margin: -2px 5px 0 5px;'
        }
    }
});
