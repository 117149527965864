import { dateString, generateTimestamp, getToday, isDST } from '@/util/dates';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
import SectionHero from '@/components/SectionHero.vue';
import AddressBar from '@/components/AddressBar.vue';
import SpaceDetails from '@/Space/components/SpaceDetails.vue';
import SectionNearby from '@/components/SectionNearby.vue';
import SectionLocation from '@/components/SectionLocation.vue';
import BookingSummary from '@/Booking/components/BookingSummary.vue';
import TagsList from '@/Tag/TagsList.vue';
import { withAuthMutationHook } from '@/util/hooks';
import { getItemLocalStorage } from '@/util/utils';
import SpaceActivitiesAccordion from '@/Space/components/SpaceActivitiesAccordion.vue';
import SectionDateTime from '@/Space/components/SectionDateTime.vue';
import MobileBookingFooter from '@/Space/components/MobileBookingFooter.vue';
import SocialDiscount from '@/Space/components/SocialDiscount.vue';
import UnpaidReservationModal from '@/Space/components/UnpaidReservationModal.vue';
import SpaceFacilities from '@/Space/components/SpaceFacilities.vue';
import SpaceFeatures from '@/Space/components/SpaceFeatures.vue';
import SpaceConfigurations from '@/Space/components/SpaceConfigurations.vue';
import PreSaveActionModal from '@/shared/components/PreSaveActionModal.vue';
import BookingConfirmation from '@/components/BookingConfirmationModal.vue';
import OrganisationRating from '@/components/OrganisationRating.vue';
import { OrderItemType, SystemServiceType, useCreateBookingMutation, UserRoleState, useSpaceServicesAvailabilityQuery, useGetBookingQuery, SpaceBookingType, ChatMessageType, useUpdateSpaceSeoDetailsMutation } from '@/generated-types/graphql.types';
import SpaceInfo from '@/Space/components/SpaceInfo.vue';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { ServerErrors } from '@/util/graphql';
import Coupon from '@/Coupon/Coupon.vue';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useBookingCalculations, useCopyLink, useUpdateQueryParams, useSpaceChat } from '@/Space/space.hooks';
import VenueRules from '@/pages/customer/VenueRules.vue';
import IncrementInput from '@/Space/components/IncrementInput.vue';
import SocialLocationModal from '@/components/SocialLocationModal.vue';
import { SpacePageState, SpacePageQueryParams, BookingSummaryState } from '@/Space/space.state';
import { BookingRoutes } from '@/Booking/booking.const';
import PageFAQ from '@/components/PageFAQ.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    name: 'Space',
    components: {
        AddressBar,
        BookingConfirmation,
        BookingSummary,
        Coupon,
        IncrementInput,
        MobileBookingFooter,
        OrganisationRating,
        PageFAQ,
        PreSaveActionModal,
        SaveFooter,
        SectionDateTime,
        SectionHero,
        SectionLocation,
        SectionNearby,
        SocialDiscount,
        SocialLocationModal,
        SpaceActivitiesAccordion,
        SpaceConfigurations,
        SpaceDetails,
        SpaceFacilities,
        SpaceFeatures,
        SpaceInfo,
        TagsList,
        UnpaidReservationModal,
        VenueRules
    },
    props: {
        space: {
            type: Object,
            required: true
        },
        spaceImages: {
            type: Array,
            default: []
        },
        spaceSeoDetails: {
            type: Object,
            required: true
        },
        injectData: {
            type: Function,
            required: true
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const spaceFacilitiesComponent = ref(null);
        const toast = useToast();
        // Normalized space attributes block start
        const spaceCategories = computed(() => {
            if (!props.space.space_categories)
                return [];
            return props.space.space_categories.filter(Boolean);
        });
        const spaceConfigurations = computed(() => {
            return props.space.space_configurations.filter(Boolean);
        });
        const spaceFeatures = computed(() => {
            return props.space.space_features.filter(Boolean);
        });
        const spaceServices = computed(() => {
            if (!props.space.space_services)
                return [];
            return props.space.space_services.filter(Boolean);
        });
        const venueServices = computed(() => {
            if (!props.space.venue.venue_services)
                return [];
            return props.space.venue.venue_services.filter(Boolean);
        });
        // Normalized space attributes block end
        const defaultConfiguration = computed(() => {
            return spaceConfigurations.value.find((configuration) => configuration.is_default);
        });
        const spaceConfiguration = ref(defaultConfiguration.value);
        const spaceTagsVisible = ref(false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const showMap = ref(false);
        const hasSlotsBeenSelected = ref(false);
        const editable = ref(false);
        const originalCouponDiscount = ref(null);
        const originalCouponId = ref(null);
        const { calculationInput, executeCalculations, onCalculationsDone } = useBookingCalculations(context);
        const { updateQueryParams } = useUpdateQueryParams(context);
        const { copyLink } = useCopyLink(context);
        const { createChat } = useSpaceChat(context, performAccountFlowAction);
        const onMapShow = () => {
            showMap.value = !showMap.value;
            if (showMap.value) {
                root.$scrollTo('#map');
            }
        };
        const modalSocialStatus = ref(false);
        const onCountChanged = (val) => {
            bookingSummary.attendees_selected_count = val;
            updateQueryParams({
                bookingSummary,
                editable: editable.value,
                couponDiscount: originalCouponDiscount.value,
                couponId: originalCouponId.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const state = new SpacePageState().state;
        const queryParamsClass = new SpacePageQueryParams();
        const queryParams = queryParamsClass.params;
        queryParamsClass.readQuery();
        const bookingSummaryClass = new BookingSummaryState();
        const bookingSummary = bookingSummaryClass.summary;
        const hasUnfinishedAction = ref(false);
        const unfinishedActionType = ref('');
        const acceptDiscount = ref(false);
        const discountApplied = ref(false);
        const coupon = ref(null);
        const forceApplyCoupon = ref(false);
        const isReservationsLimitReached = ref(false);
        const reservationsLimit = ref(5);
        const selectedSlots = ref({
            slotFrom: null,
            slotTo: null
        });
        /**
         * Watch selectedSlots value and update booking summary accordingly
         */
        watch(() => [selectedSlots.value.slotFrom, selectedSlots.value.slotTo], () => {
            if (selectedSlots.value.slotFrom && selectedSlots.value.slotTo) {
                bookingSummary.slot_start = selectedSlots.value.slotFrom;
                bookingSummary.slot_end = selectedSlots.value.slotTo;
                if (selectedSlots.value.slotTo > selectedSlots.value.slotFrom) {
                    hasSlotsBeenSelected.value = true;
                }
            }
        }, { immediate: true });
        const availability = reactive({
            errors: []
        });
        const servicesAvailabilityVariables = reactive({
            space_id: 0,
            booking_start: '',
            booking_end: '',
            booking_edit_mode: null
        });
        const servicesAvailabilityOptions = reactive({ enabled: false });
        const { result: services, refetch: refetchServices } = useSpaceServicesAvailabilityQuery(servicesAvailabilityVariables, servicesAvailabilityOptions);
        let bookingOrigin = ref(null);
        let isRequestBookingUpdateButtonDisabled = ref(false);
        const { onResult: getOriginBooking } = useGetBookingQuery({ id: +sessionStorage.getItem('bookingId') }, {
            fetchPolicy: 'no-cache',
            enabled: editable.value
        });
        getOriginBooking(result => {
            if (!result?.data?.specific_booking)
                return;
            const booking = result.data.specific_booking;
            const _isDST = isDST(booking.slot_start);
            // TODO: Fix
            bookingOrigin.value = {
                slotEnd: _isDST ? booking.slot_end + 3600 : booking.slot_end,
                slotStart: _isDST ? booking.slot_start + 3600 : booking.slot_start,
                attendees: booking.attendees,
                isDiscountRequested: booking.is_discount_applied,
                configuration: booking.order.order_items.find(service => service.order_item_type === OrderItemType.Configuration).reference_id,
                'facilities[]': booking.order.order_items
                    .filter(item => {
                    return (item.order_item_type === OrderItemType.Service &&
                        item.service_type === SystemServiceType.Facility);
                })
                    .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                'caterings[]': booking.order.order_items
                    .filter(item => {
                    return (item.order_item_type === OrderItemType.Service &&
                        item.service_type === SystemServiceType.Catering);
                })
                    .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                editable: true,
                couponDiscount: booking.booking_meta?.coupon?.discount_percentage,
                couponId: booking.booking_meta?.coupon?.coupon_id
            };
            updateQueryParams({
                bookingSummary,
                editable: editable.value,
                couponDiscount: originalCouponDiscount.value,
                couponId: originalCouponId.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        });
        watch(() => editable.value, () => {
            if (editable.value) {
                servicesAvailabilityVariables.booking_edit_mode = {
                    booking_id: +sessionStorage.getItem('bookingId')
                };
            }
        });
        /**
         * Restore booking summary from query params (if any).
         * This is used when user booking editing or when using share booking link.
         */
        const restoreBookingSummaryFromQueryParams = () => {
            if (!Object.keys(root.$route.query).length)
                return;
            discountApplied.value = queryParams.isDiscountRequested;
            editable.value = queryParams.editable;
            originalCouponDiscount.value = queryParams.couponDiscount;
            originalCouponId.value = queryParams.couponId;
            selectedSlots.value.slotFrom = queryParams.slotStart;
            selectedSlots.value.slotTo = queryParams.slotEnd;
            if (queryParams.configuration) {
                spaceConfiguration.value = spaceConfigurations.value.find((configuration) => configuration.configuration_id === queryParams.configuration);
            }
            if (queryParams.attendees &&
                spaceConfiguration.value.max_guests < queryParams.attendees) {
                spaceConfiguration.value =
                    spaceConfigurations.value
                        .sort((a, b) => a.max_guests - b.max_guests)
                        .find((configuration) => configuration.max_guests >= queryParams.attendees) || spaceConfiguration.value;
            }
            bookingSummary.order.order_items = [];
            setBasicOrderItems();
            queryParams.caterings.forEach(item => {
                bookingSummary.order.order_items.push({
                    reference_id: item.referenceId,
                    order_item_type: OrderItemType.Service,
                    quantity: item.quantity,
                    price: null,
                    is_mandatory: item.is_mandatory,
                    system_service_id: item.system_service_id,
                    name: item.service_name,
                    service_type: SystemServiceType.Catering
                });
            });
            queryParams.facilities.forEach(item => {
                bookingSummary.order.order_items.push({
                    reference_id: item.referenceId,
                    order_item_type: OrderItemType.Service,
                    quantity: item.quantity,
                    price: null,
                    is_mandatory: item.is_mandatory,
                    system_service_id: item.system_service_id,
                    name: item.service_name,
                    service_type: SystemServiceType.Facility
                });
            });
            bookingSummary.attendees_selected_count = queryParams.attendees || 0;
        };
        const selectedDay = computed(() => globalThis.$store.getters['$_calendar/selectedDay'] || getToday());
        /**
         * When mounted:
         * - restore booking summary from query params (if any)
         * - if URL query params exist - scroll user to the calendar
         * - add calendar listeners
         */
        onMounted(() => {
            restoreBookingSummaryFromQueryParams();
            if (Object.keys(root.$route.query).length) {
                setTimeout(() => {
                    root.$scrollTo('#calendar', 1500, {
                        offset: -60
                    });
                }, 500);
            }
            // Add calendar listeners to update selectedSlots when user selects date/time on calendar
            EventBus.$on('updateBookingStartTime', time => {
                selectedSlots.value.slotFrom = generateTimestamp(time, selectedDay.value, globalThis.$timezone);
            });
            EventBus.$on('updateBookingEndTime', time => {
                selectedSlots.value.slotTo = generateTimestamp(time, selectedDay.value, globalThis.$timezone);
            });
        });
        /**
         * Watch selected attendees number and remove respective validation error message if any
         */
        watch(() => bookingSummary.attendees_selected_count, (newValue, oldValue) => {
            if (oldValue === 0 && newValue !== 0) {
                state.error.text = '';
                state.error.type = '';
            }
        }, { immediate: true });
        /**
         * Watch selected booking slots and initiate preliminary booking calculations fetch
         */
        watch(() => [bookingSummary.slot_start, bookingSummary.slot_end], (newValue, oldValue) => {
            const [newSlotStart, newSlotEnd] = newValue;
            let [oldSlotStart, oldSlotEnd] = [0, 0];
            if (Array.isArray(oldValue)) {
                [oldSlotStart, oldSlotEnd] = oldValue;
            }
            if (newSlotStart === oldSlotStart && newSlotEnd === oldSlotEnd)
                return;
            if (newSlotStart && newSlotEnd && newSlotStart !== newSlotEnd) {
                state.error.text = '';
                state.error.type = '';
                const bookingStart = dateString(newSlotStart, globalThis.$timezone);
                const bookingEnd = dateString(newSlotEnd, globalThis.$timezone);
                servicesAvailabilityOptions.enabled = true;
                servicesAvailabilityVariables.space_id = props.space.space_id;
                servicesAvailabilityVariables.booking_start = bookingStart;
                servicesAvailabilityVariables.booking_end = bookingEnd;
                calculationInput.input.booking_start = bookingStart;
                calculationInput.input.booking_end = bookingEnd;
                refetchServices(servicesAvailabilityVariables);
                executeCalculations({
                    isDiscountApplied: discountApplied.value,
                    couponId: coupon?.value?.coupon_id,
                    editable: editable.value,
                    bookingSummary,
                    couponDiscount: originalCouponDiscount.value,
                    bookingOrigin: bookingOrigin.value,
                    isRequestBookingUpdateButtonDisabled
                });
            }
        }, { immediate: true });
        /**
         * Sets basic order items: space and configuration
         */
        const setBasicOrderItems = () => {
            // SPACE
            bookingSummary.order.order_items.push({
                reference_id: props.space.space_id,
                order_item_type: OrderItemType.Space,
                quantity: 1,
                price: null
            });
            // CONFIGURATION
            bookingSummary.order.order_items.push({
                reference_id: spaceConfiguration.value.configuration_id,
                order_item_type: OrderItemType.Configuration,
                quantity: 1,
                configuration_type: spaceConfiguration.value.configuration_type,
                price: null
            });
        };
        watch(() => props.space, () => {
            // Show unfinished action modal
            const preSavedType = localStorage.getItem('preSavedType');
            const preSavedSpace = getItemLocalStorage('preSavedSpace');
            if (preSavedType &&
                (preSavedType === PRE_SAVE_ACTION_TYPES.BOOKING ||
                    preSavedType === PRE_SAVE_ACTION_TYPES.CHAT ||
                    preSavedType === PRE_SAVE_ACTION_TYPES.COUPON_CODE) &&
                preSavedSpace &&
                preSavedSpace.space_id === props.space.space_id) {
                hasUnfinishedAction.value = true;
                unfinishedActionType.value = preSavedType;
            }
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'set_city',
                category: 'geo',
                action: 'space_page_opened',
                label: props.space.venue.venue_geo.city.seo_entity_name,
                value: props.space.venue.venue_geo.city.seo_entity_name,
                noninteraction: true
            });
            const cachedBookingSummary = bookingSummaryClass.getCachedSummary(props.space.space_id, discountApplied);
            if (!cachedBookingSummary) {
                bookingSummary.is_discount_applied = discountApplied.value;
                bookingSummary.is_coupon_applied =
                    !!props.space.coupon ||
                        (editable.value && originalCouponDiscount.value !== null);
                bookingSummary.discount_rate =
                    props.space.coupon?.discount_percentage ||
                        (editable.value && originalCouponDiscount.value !== null
                            ? originalCouponDiscount.value
                            : null) ||
                        props.space.discount_percentage ||
                        null;
                bookingSummary.space = props.space;
                bookingSummary.attendees_max = spaceConfiguration.value
                    .max_guests;
                setBasicOrderItems();
            }
            if (props.space.coupon) {
                coupon.value = props.space.coupon;
                discountApplied.value = false;
            }
            if (editable.value && originalCouponDiscount.value !== null) {
                coupon.value = {
                    coupon_id: originalCouponId.value,
                    discount_percentage: originalCouponDiscount.value
                };
                discountApplied.value = false;
            }
        }, { immediate: true });
        // mutation to create a booking
        const { mutate } = withAuthMutationHook(useCreateBookingMutation)({
            clientId: 'legacy'
        });
        onCalculationsDone(({ data }) => {
            const calculations = data?.get_booking_preliminary_calculation;
            if (calculations) {
                isReservationsLimitReached.value =
                    calculations.is_reservations_limit_reached;
                reservationsLimit.value = calculations.reservations_limit;
                bookingSummary.order.discount_total = calculations.discount;
                bookingSummary.order.order_total_vat_excl = calculations.total_vat_excl;
                bookingSummary.order.order_total = calculations.total_vat_incl;
                const spaceOrderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Space);
                if (spaceOrderItem) {
                    spaceOrderItem.price =
                        calculations.space_price_with_service_fee_vat_excl;
                }
                const configurationOrderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Configuration);
                if (configurationOrderItem) {
                    configurationOrderItem.price =
                        calculations.configuration_price_with_service_fee_vat_excl;
                }
                bookingSummary.total_vat_excl = calculations.total_vat_excl;
                bookingSummary.total = calculations.total_vat_incl;
                bookingSummary.vat_21 = calculations.vat_21;
                bookingSummary.vat_9 = calculations.vat_9;
                calculations.order_items?.forEach(calculatedOrderItem => {
                    const orderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Service &&
                        item.reference_id === calculatedOrderItem.reference_id);
                    if (orderItem) {
                        orderItem.price =
                            calculatedOrderItem.order_item_price_with_service_fee_vat_excl;
                    }
                });
            }
        });
        const updateConfiguration = (configuration) => {
            bookingSummary.attendees_max = configuration.max_guests;
            if (bookingSummary.attendees_max < bookingSummary.attendees_selected_count) {
                bookingSummary.attendees_selected_count = bookingSummary.attendees_max;
                root.$scrollTo('#guests-amount', 1000, {
                    offset: -100
                });
                toast.error(root.$i18n.t('customer.space.attendees.toast_message'));
            }
            // update configuration type and ID in summary
            const bookingSummaryConfiguration = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Configuration);
            if (bookingSummaryConfiguration) {
                bookingSummaryConfiguration.configuration_type =
                    configuration.configuration_type;
                bookingSummaryConfiguration.reference_id =
                    configuration.configuration_id;
            }
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const checkAvailability = () => {
            const bookingServices = bookingSummary.order.order_items.filter(item => item?.order_item_type === OrderItemType.Service);
            let availableServices = [];
            servicesAvailabilityOptions.enabled = true;
            servicesAvailabilityVariables.space_id = props.space.space_id;
            servicesAvailabilityVariables.booking_start = dateString(bookingSummary.slot_start, globalThis.$timezone);
            servicesAvailabilityVariables.booking_end = dateString(bookingSummary.slot_end, globalThis.$timezone);
            refetchServices(servicesAvailabilityVariables)?.then(res => {
                availableServices = res?.data?.space_service_balance;
                if (availableServices) {
                    bookingServices.forEach(s => {
                        let found = availableServices.find(service => service.venue_service.system_service_id === s.reference_id &&
                            service.remaining_inventory < s.quantity);
                        if (found) {
                            availability.errors.push(found.venue_service.system_service_id);
                            const index = bookingSummary.order.order_items.findIndex(el => el?.order_item_type === OrderItemType.Service &&
                                el.reference_id === found.venue_service.system_service_id);
                            if (index > -1)
                                bookingSummary.order.order_items.splice(index, 1);
                        }
                    });
                    if (availability.errors[0]) {
                        root.$scrollTo(`#service-${availability.errors[0]}`, 1500, {
                            offset: -100
                        });
                    }
                }
            });
        };
        const makeBooking = (space, force = false, bookingIdToCancel) => {
            resetPreSaveAction();
            return () => {
                const data = {
                    bookingIdToCancel: bookingIdToCancel,
                    forceCancel: force,
                    attendees: bookingSummary.attendees_selected_count,
                    bookingEnd: dateString(bookingSummary.slot_end, globalThis.$timezone),
                    bookingStart: dateString(bookingSummary.slot_start, globalThis.$timezone),
                    isPendingForDiscount: bookingSummary.is_discount_applied,
                    spaceId: space.space_id,
                    couponId: coupon?.value?.coupon_id,
                    orderItems: bookingSummary.order.order_items.map(item => {
                        return {
                            orderItemType: item.order_item_type,
                            quantity: item.quantity,
                            referenceId: item.reference_id
                        };
                    }),
                    bookingEditMode: editable.value
                        ? {
                            bookingId: +sessionStorage.getItem('bookingId')
                        }
                        : null
                };
                mutate({ input: data }, {})
                    .then(res => {
                    localStorage.setItem('cachedBookingSummary', JSON.stringify(bookingSummary));
                    globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKING}/${res?.data?.mutationViewerApiKey?.postApiBookingSpace?.bookingId}`));
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        const error = ServerErrors.extractGQLError(graphQLErrors);
                        state.error.text = `common.errors.${error?.error.key}`;
                        state.error.type = 'graphQLErrors';
                        root.$scrollTo('#calendar', 1500, {
                            offset: -60
                        });
                        if (error.error.key === 'booking.inventory_limits_violated') {
                            checkAvailability();
                        }
                    }
                });
            };
        };
        const checkRequiredBookingFields = (event, force = false) => {
            if (force)
                state.showUnpaidReservationModal = false;
            if (bookingSummary.slot_end === bookingSummary.slot_start) {
                state.error.text = 'customer.space.book_preview.booking_slots_error';
                state.error.type = 'calendar';
                root.$scrollTo('#calendar', 1500, {
                    offset: -60
                });
                return;
            }
            if (bookingSummary.attendees_selected_count === 0) {
                state.error.text = 'customer.space.attendees.error_text';
                state.error.type = 'attendees';
                root.$scrollTo('#guests-amount', 1000, {
                    offset: -100
                });
                return;
            }
            if (spaceFacilitiesComponent.value) {
                const requiredIds = spaceFacilitiesComponent.value.checkMandatory();
                if (requiredIds.length) {
                    state.error.text =
                        'common.errors.booking.mandatory_services_violated';
                    state.error.type = 'is_mandatory';
                    root.$scrollTo(`#service-${requiredIds[0]}`, 1500, {
                        offset: -100
                    });
                    return;
                }
                else {
                    state.error.text = '';
                    state.error.type = '';
                }
            }
            // show unpaid reservations modal if user has reached the max reservations limit
            if (isReservationsLimitReached.value && !force) {
                state.showUnpaidReservationModal = true;
                return;
            }
            if (props.space.space_booking_type === SpaceBookingType.Direct &&
                !discountApplied.value) {
                handleSubmit(event, force);
            }
            else {
                state.showBookingConfirmationModal = true;
            }
        };
        const handleSubmit = (event, force = false) => {
            let bookingIdToCancel;
            if (typeof event === 'number') {
                bookingIdToCancel = event;
            }
            if (performAccountFlowAction.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.BOOKING,
                    space: props.space,
                    summary: bookingSummary
                });
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            makeBooking(props.space, force, bookingIdToCancel)();
        };
        const continuePreSaveAction = () => {
            if (performAccountFlowAction.value) {
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            let preSavedType = localStorage.getItem('preSavedType');
            let preSavedSpace = getItemLocalStorage('preSavedSpace');
            let preSavedBookingSummary = getItemLocalStorage('preSavedBookingSummary');
            let preSavedChatType = localStorage.getItem('preSavedChatType');
            if (preSavedBookingSummary) {
                Object.assign(bookingSummary, preSavedBookingSummary);
            }
            if (preSavedType === PRE_SAVE_ACTION_TYPES.BOOKING) {
                makeBooking(preSavedSpace)();
            }
            else if (preSavedType === PRE_SAVE_ACTION_TYPES.CHAT) {
                createChat({ space: preSavedSpace, chatMessageType: preSavedChatType });
            }
            else if (preSavedType === PRE_SAVE_ACTION_TYPES.COUPON_CODE) {
                forceApplyCoupon.value = true;
            }
        };
        const cancelPreSaveSpaceAction = () => {
            hasUnfinishedAction.value = false;
            unfinishedActionType.value = '';
            resetPreSaveAction();
        };
        const onUpdateEvent = (_, item, count) => {
            if (availability.errors.includes(item.system_service_id)) {
                const index = availability.errors.findIndex(el => el === item.system_service_id);
                availability.errors.splice(index, 1);
            }
            const orderItem = {
                order_item_type: OrderItemType.Service,
                quantity: count,
                reference_id: item.system_service_id
            };
            bookingSummary.order.order_items.push({
                ...orderItem,
                price: null,
                is_mandatory: item.is_mandatory,
                name: item.system_service_name,
                system_service_id: item.system_service_id,
                service_type: item.system_service_type
            });
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const onRemoveEvent = (_, item) => {
            if (availability.errors.includes(item?.system_service_id)) {
                availability.errors = [];
            }
            // remove service from booking summary
            bookingSummary.order.order_items =
                bookingSummary.order.order_items.filter(_item => _item.reference_id !== item.system_service_id);
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const discountRequested = () => {
            if (!discountApplied.value) {
                // If user applies for a discount
                acceptDiscount.value = true;
                root.$scrollTo('#discount');
            }
            else {
                discountSelected();
            }
        };
        const discountSelected = () => {
            if (!bookingSummary.slot_start || !bookingSummary.slot_end) {
                // slots must be selected to request a discount
                state.error.text = globalThis.$i18n.t('customer.space.book_preview.booking_slots_error');
                state.error.type = 'calendar';
                root.$scrollTo('#calendar', 1500, {
                    offset: -60
                });
                return;
            }
            // Toggle discount applied boolean
            discountApplied.value = !discountApplied.value;
            acceptDiscount.value = false;
            bookingSummary.is_discount_applied = discountApplied.value;
            bookingSummary.discount_rate = props.space.discount_percentage || null;
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const removeService = item => {
            EventBus.$emit('removeService', item);
            onRemoveEvent(item.service_type.toLowerCase(), {
                ...item,
                system_service_id: item.reference_id
            });
        };
        /**
         * Send event to SpacePageWrapper to open images carousel
         * @param imageIndex - the image that needs to be opened as main image
         */
        const onShowImages = (imageIndex) => {
            emit('onShowImages', imageIndex);
        };
        const handleCouponAction = (appliedCoupon) => {
            coupon.value = appliedCoupon ? appliedCoupon : null;
            bookingSummary.is_coupon_applied = !!appliedCoupon;
            bookingSummary.discount_rate = coupon?.value?.discount_percentage || null;
            if (calculationInput.input.booking_start)
                executeCalculations({
                    isDiscountApplied: discountApplied.value,
                    couponId: coupon?.value?.coupon_id,
                    editable: editable.value,
                    bookingSummary,
                    couponDiscount: originalCouponDiscount.value,
                    bookingOrigin: bookingOrigin.value,
                    isRequestBookingUpdateButtonDisabled
                });
        };
        const backToOriginal = async () => {
            globalThis.$router.back();
        };
        const onSpaceTagsVisible = (isVisible) => {
            if (isVisible) {
                spaceTagsVisible.value = true;
            }
        };
        const seoUpdatesInProgress = ref(false);
        const isSpaceInfoUpdated = ref(false);
        const seoSpaceH1 = ref(null);
        const seoSpaceH2 = ref('');
        const seoSpaceDescription = ref('');
        const { mutate: changeSeoInfo } = useUpdateSpaceSeoDetailsMutation();
        const onRevertChanges = () => {
            seoSpaceH1.value.innerHTML = props.spaceSeoDetails.h1;
            EventBus.$emit('revertAdminUpdates');
            seoUpdatesInProgress.value = false;
        };
        const handleAdminUpdates = (element, updates) => {
            if (element === 'seoSpaceH2') {
                seoSpaceH2.value = updates;
            }
            else {
                seoSpaceDescription.value = updates;
            }
            handleAdminInput();
        };
        const handleAdminInput = () => {
            seoUpdatesInProgress.value = true;
        };
        /**
         * Handles partial rich text formatting (bold and italic text)
         * @param event
         */
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                switch (event.key.toLowerCase()) {
                    case 'b':
                        event.preventDefault();
                        document.execCommand('bold');
                        break;
                    case 'i':
                        event.preventDefault();
                        document.execCommand('italic');
                        break;
                }
            }
        };
        const hasChanges = () => {
            return seoUpdatesInProgress.value;
        };
        const onClose = () => {
            isSpaceInfoUpdated.value = false;
        };
        const onSaveChanges = () => {
            const input = {
                space_id: props.space.space_id,
                h1: seoSpaceH1.value?.innerHTML || props.spaceSeoDetails.h1,
                h2: seoSpaceH2.value || props.spaceSeoDetails.h2,
                description: seoSpaceDescription.value || props.spaceSeoDetails.description,
                locale: globalThis.$i18n.locale.toUpperCase()
            };
            changeSeoInfo({ input })
                .then(() => {
                emit('refetchSeoDetails');
                seoUpdatesInProgress.value = false;
                isSpaceInfoUpdated.value = true;
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    console.log(graphQLErrors);
                }
            });
        };
        const isSaved = () => {
            return isSpaceInfoUpdated.value;
        };
        const scrollShadow = reactive({
            top: false,
            bottom: false
        });
        const onSummaryScroll = (e) => {
            scrollShadow.bottom =
                e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight > 1;
            scrollShadow.top = e.target.scrollTop !== 0;
        };
        return {
            acceptDiscount,
            availability,
            bookingSummary,
            coupon,
            discountApplied,
            editable,
            forceApplyCoupon,
            hasSlotsBeenSelected,
            hasUnfinishedAction,
            isRequestBookingUpdateButtonDisabled,
            modalSocialStatus,
            reservationsLimit,
            scrollShadow,
            selectedSlots,
            seoSpaceH1,
            seoSpaceH2,
            seoSpaceDescription,
            showMap,
            spaceCategories,
            spaceConfigurations,
            spaceFacilitiesComponent,
            spaceFeatures,
            spaceServiceBalance: computed(() => services.value?.space_service_balance || []),
            spaceServices,
            spaceTagsVisible,
            state,
            venueServices,
            unfinishedActionType,
            user,
            ChatMessageType,
            SpaceBookingType,
            UserRoleState,
            backToOriginal,
            cancelPreSaveSpaceAction,
            checkRequiredBookingFields,
            continuePreSaveAction,
            copyLink,
            createChat,
            discountRequested,
            discountSelected,
            isSaved,
            hasChanges,
            handleAdminInput,
            handleAdminUpdates,
            handleCouponAction,
            handleKeyDown,
            handleSubmit,
            onClose,
            onCountChanged,
            onSaveChanges,
            onMapShow,
            onRemoveEvent,
            onRevertChanges,
            onShowImages,
            onSpaceTagsVisible,
            onSummaryScroll,
            onUpdateEvent,
            removeService,
            updateConfiguration
        };
    }
});
