import { defineComponent, watch, computed } from '@vue/composition-api';
import { useGetSpaceSeoQuery } from '@/generated-types/graphql.types';
import { useMeta } from '@/shared/composables/useMeta';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    name: 'SpaceSeo',
    props: {
        refetchTrigger: {
            type: Number,
            required: true
        },
        spaceSlug: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const { emit } = context;
        const { result, refetch } = useGetSpaceSeoQuery({ slug: props.spaceSlug }, { fetchPolicy: 'no-cache' });
        const spaceSeoData = computed(() => {
            const data = result.value?.specific_instance_by_slug;
            return {
                h1: data?.seo.h1 || '',
                h2: data?.seo.h2 || '',
                description: data?.seo.description || '',
                metaTitle: data?.seo.meta_title || '',
                metaDescription: data?.seo.meta_description || '',
                faqQuestions: data?.faqs.map((question) => {
                    return {
                        question: question.question,
                        answer: question.answer
                    };
                }) || [],
                extraSeo: data?.seo_extra.map((extra) => {
                    return {
                        title: extra.title,
                        description: extra.description
                    };
                }) || []
            };
        });
        /**
         * Watch for changes in meta title and description and update <head> accordingly
         */
        watch(() => [spaceSeoData.value.metaTitle, spaceSeoData.value.metaDescription], () => {
            useMeta({
                title: spaceSeoData.value.metaTitle,
                description: spaceSeoData.value.metaDescription
            });
        });
        /**
         * Watch for changes in refetch trigger and re-fetch SEO details
         */
        watch(() => props.refetchTrigger, (newTrigger, oldTrigger) => {
            if (newTrigger !== oldTrigger) {
                refetch();
            }
        });
        /**
         * Emit spaceSeoData to parent after any changes
         */
        watch(() => spaceSeoData.value, () => {
            emit('spaceSeoDataChanged', spaceSeoData.value);
        });
        // Re-fetch SEO details on language change
        EventBus.$on('languageSelected', refetch);
        return {};
    }
});
