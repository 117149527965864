import { render, staticRenderFns } from "./SpaceInfo.vue?vue&type=template&id=2c363343&scoped=true"
import script from "./SpaceInfo.vue?vue&type=script&lang=ts"
export * from "./SpaceInfo.vue?vue&type=script&lang=ts"
import style0 from "./SpaceInfo.vue?vue&type=style&index=0&id=2c363343&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c363343",
  null
  
)

export default component.exports