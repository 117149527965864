var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.availableSpaceServices.default.length)?_c('ListWithMore',{attrs:{"list":_vm.availableSpaceServices.default,"type":"facility","model-prop-name":'system_service_name',"title":_vm.$t('customer.space.default_facilities'),"colored-title":"","list-type":'service'}}):_vm._e(),(_vm.availableSpaceServices.onDemand.length)?_c('ListWithMore',{attrs:{"id":"facilities","list":_vm.availableSpaceServices.onDemand,"title":_vm.$t('customer.space.additional_facilities.title'),"colored-title":"","type":"facility","has-slots-been-selected":_vm.hasSlotsBeenSelected,"list-type":'demand',"remove-demand":_vm.removeItemCallback,"update-demand":_vm.updateItemCallback}}):_vm._e(),(
      (_vm.availableVenueServices.updated &&
        _vm.availableVenueServices.facilities.length) ||
      (!_vm.availableVenueServices.updated && _vm.staticServices.facilities.length)
    )?_c('ListWithMore',{attrs:{"list":_vm.availableVenueServices.updated
        ? _vm.availableVenueServices.facilities
        : _vm.staticServices.facilities,"errors":_vm.availability.errors,"title":_vm.$t('customer.space.additional_facilities.venue_list_label'),"colored-title":"","type":"facility","has-slots-been-selected":_vm.hasSlotsBeenSelected,"list-type":'demand',"remove-demand":_vm.removeItemCallback,"update-demand":_vm.updateItemCallback}}):_vm._e(),(
      (_vm.availableVenueServices.updated &&
        _vm.availableVenueServices.catering.length) ||
      (!_vm.availableVenueServices.updated && _vm.staticServices.catering.length)
    )?_c('ListWithMore',{attrs:{"list":_vm.availableVenueServices.updated
        ? _vm.availableVenueServices.catering
        : _vm.staticServices.catering,"title":_vm.$t('customer.space.catering.title'),"colored-title":"","type":"catering","has-slots-been-selected":_vm.hasSlotsBeenSelected,"list-type":'demand',"remove-demand":_vm.removeItemCallback,"update-demand":_vm.updateItemCallback}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }