import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api';
import Loader from '@/components/Loader.vue';
import Space from '@/Space/Space.vue';
import { useGetSpaceQuery } from '@/generated-types/graphql.types';
import EventBus from '@/shared/services/eventBus';
import { spaceSchemaOrgInfo } from '@/Space/space.seo';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { SpaceRoutes } from '@/Space/space.const';
import useStructuredData from '@/shared/composables/useStructuredData';
import LowSpaceRating from '@/Space/components/LowSpaceRating.vue';
import SpaceSeo from '@/Space/components/SpaceSeo.vue';
import ImageViewer from '@/components/ImageViewer.vue';
export default defineComponent({
    name: 'SpacePageWrapper',
    components: {
        ImageViewer,
        Loader,
        LowSpaceRating,
        Space,
        SpaceSeo
    },
    setup(_, context) {
        const { root } = context;
        const { hash, name, reference } = root.$route.params;
        const slug = `/${hash}/${name}/${reference}`;
        const { result } = useGetSpaceQuery({ slug }, { fetchPolicy: 'no-cache' });
        const space = computed(() => result.value?.specific_instance_by_slug);
        const spaceImages = computed(() => {
            if (!space.value || !space.value.space_images)
                return [];
            return space.value.space_images.filter(Boolean).map((image) => {
                return {
                    image_url: image.image_url
                };
            });
        });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const injectData = (structuredData) => {
            const data = {
                '@context': 'https://schema.org',
                '@graph': []
            };
            data['@graph'].push(structuredData);
            injectStructuredData({
                ...data
            });
        };
        watch(() => space.value, () => {
            if (space.value && space.value.__typename === 'Space') {
                // Emit space object to the SpaceHeader.vue component for breadcrumbs rendering
                EventBus.$emit('spaceLoaded', space.value);
                space.value.space_services
                    .filter(Boolean)
                    .map((spaceService) => (spaceService.remaining_inventory = spaceService.is_on_demand
                    ? 0
                    : null));
                // If venue settings allow own catering, append it to the space features
                if (space.value.venue.allow_customer_catering) {
                    space.value.space_features.push({
                        feature_type: 'VENUE',
                        feature_name: 'own_catering_allowed',
                        feature_id: 0
                    });
                }
                const _spaceName = space.value.internal_name;
                const _venue = space.value.venue;
                useMeta({
                    keywords: root.$i18n
                        .t('meta.general.meta_keywords', {
                        returnObjects: true
                    })
                        .map((keyword) => `${keyword} ${_venue.venue_name} ${_spaceName} ${_venue.venue_geo.city.seo_entity_name}`)
                        .join(','),
                    imageUrl: `${space.value.space_images[0]?.image_url}/tr:w-300,h-230` || '',
                    url: `${SITE_URL}/${SpaceRoutes.SPACE}${space.value.space_url}`,
                    // remove page from indexing if slots are set (== if any query params exist)
                    noIndex: Object.keys(root.$route.query).length > 0
                });
                injectData(spaceSchemaOrgInfo(space.value));
            }
        });
        EventBus.$on('onBreadcrumbsReady', data => {
            injectData(data);
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        const selectedImageIndex = ref(0);
        const isImageCarouselOpen = ref(false);
        const onShowImages = (imageIndex) => {
            selectedImageIndex.value = imageIndex;
            isImageCarouselOpen.value = true;
        };
        const seoDetailsRefetchTrigger = ref(0);
        const spaceSeoDetails = ref({
            h1: '',
            metaTitle: '',
            metaDescription: '',
            faqQuestions: [],
            extraSeo: []
        });
        const onSpaceSeoDataChanged = (spaceSeoData) => {
            spaceSeoDetails.value = spaceSeoData;
        };
        return {
            isImageCarouselOpen,
            selectedImageIndex,
            seoDetailsRefetchTrigger,
            space,
            spaceImages,
            spaceSeoDetails,
            injectData,
            onShowImages,
            onSpaceSeoDataChanged
        };
    }
});
