import { UserRoleState } from '@/generated-types/graphql.types';
import { defineComponent, ref } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import { VenueRoutes } from '@/Venue/venue.const';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    name: 'AddressBar',
    props: {
        space: {
            type: Object,
            default: () => { }
        },
        user: {
            type: Object,
            default: null
        },
        spaceSeoDetails: {
            type: Object,
            required: true
        }
    },
    setup(props, context) {
        const { emit } = context;
        const seoSpaceH2 = ref(null);
        const seoSpaceDescription = ref(null);
        const handleAdminUpdates = (element) => {
            const innerTextElement = element === 'seoSpaceH2'
                ? seoSpaceH2.value?.innerHTML
                : seoSpaceDescription.value?.innerHTML;
            emit('handle-admin-updates', element, innerTextElement);
        };
        EventBus.$on('revertAdminUpdates', () => {
            if (seoSpaceH2.value) {
                seoSpaceH2.value.innerHTML = props.spaceSeoDetails.h2 || '';
            }
            if (seoSpaceDescription.value) {
                seoSpaceDescription.value.innerHTML =
                    props.spaceSeoDetails.description || '';
            }
        });
        return {
            seoSpaceH2,
            seoSpaceDescription,
            GeoPagesRoutes,
            UserRoleState,
            VenueRoutes,
            handleAdminUpdates,
            useGetLocalizedPath
        };
    }
});
