import MediumList from '@/Space/components/MediumList.vue';
import SupportedList from '@/Space/components/SupportedList.vue';
import ServiceList from '@/Space/components/ServiceList.vue';
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    components: {
        MediumList,
        SupportedList,
        ServiceList
    },
    props: {
        type: {
            type: String,
            default: ''
        },
        hasSlotsBeenSelected: {
            type: Boolean,
            default: false
        },
        title: {
            type: [String, Object],
            default: ''
        },
        coloredTitle: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => []
        },
        updateDemand: {
            type: Function,
            default: () => { }
        },
        removeDemand: {
            type: Function,
            default: () => { }
        },
        listType: {
            type: String,
            default: ''
        },
        modelPropName: {
            type: String,
            default: ''
        },
        errors: {
            type: Array,
            default: () => []
        },
        selectedId: {
            type: Number,
            default: null
        }
    },
    setup(props, { emit }) {
        const onToggleDemand = (item, value, count) => {
            if (props.updateDemand && props.removeDemand) {
                if (value) {
                    props.updateDemand(props.type, item, count);
                }
                else {
                    props.removeDemand(props.type, item);
                }
            }
        };
        const onToggleConfiguration = (item) => {
            emit('updateConfiguration', item);
        };
        return {
            selectedConfiguration: computed(() => {
                if (props.listType !== 'configuration')
                    return null;
                return props.list.find(configuration => configuration.configuration_id === props.selectedId);
            }),
            onToggleConfiguration,
            onToggleDemand
        };
    }
});
