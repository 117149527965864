import { defineComponent, ref, computed } from '@vue/composition-api';
import ModalPopup from '@/shared/components/ModalPopup.vue';
export default defineComponent({
    components: {
        ModalPopup
    },
    props: {
        organisationRating: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const isSeen = ref(false);
        const isRatingLow = computed(() => props.organisationRating <= 4);
        return {
            isRatingLow,
            isSeen
        };
    }
});
