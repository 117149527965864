import { defineComponent } from '@vue/composition-api';
import ListWithMore from '@/Space/components/ListWithMore.vue';
export default defineComponent({
    name: 'SpaceFeatures',
    components: {
        ListWithMore
    },
    props: {
        spaceFeatures: {
            type: Array,
            default: []
        }
    }
});
